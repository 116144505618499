import { AxiosError } from "axios";
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable no-case-declarations */
import { Field, FieldMetaKeys, JSONValue } from "./types";
import { FieldsData } from "@hooks/useForm/types";
import { getErrorMessage } from "@i18n/shared/errorMessages/getErrorMessages";
import { validateField as serverSideFieldValidation } from "src/api";
// datePart: 'y', 'm', 'w', 'd', 'h', 'n', 's'
export const dateDiff = (
    datePart: "y" | "m" | "w" | "d" | "h" | "n" | "s",
    fromDate: Date,
    toDate: Date,
): number => {
    const diff = toDate - fromDate;
    const divideBy = {
        w: 604800000,
        d: 86400000,
        h: 3600000,
        n: 60000,
        s: 1000,
    };

    return Math.floor(diff / divideBy[datePart.toLowerCase()]);
};

export const getServerSideValidationErrorMessage = (
    error: AxiosError,
    fieldName: string,
): string => {
    error.response?.data?.validationMessage;

    return (error.response?.data.validationMessage ??
        error.response?.data.message ??
        `${fieldName} is invalid`) as string;
};

export const canShowFieldAfterDependency = (
    field: Field,
    fieldsState: FieldsData,
) => {
    if (field.conditionalLogic && field.conditionalLogic.isActive) {
        let finalState: Boolean =
            field.conditionalLogic.operator === "AND" ? true : false;

        const conditionOperators = {
            equal: (value1: string, value2: string) => value1 === value2,
            notEqual: (value1: string, value2: string) => value1 !== value2,
        };

        field.conditionalLogic.conditions.forEach((condition) => {
            const state = conditionOperators[condition.operator](
                fieldsState[condition.codeName]?.value,
                condition.value,
            );

            finalState =
                field.conditionalLogic!.operator === "AND"
                    ? finalState && state
                    : finalState || state;
        });

        return field.conditionalLogic.action === "show"
            ? finalState
            : !finalState;
    }

    return true;
};

export const gettingDateFromDays = (days: number) => {
    const date = new Date();
    const last = new Date(date.getTime() + days * 24 * 60 * 60 * 1000);

    return last.toISOString().split("T")[0];
};
const resolveRegulation = (
    meta: null | { [key in FieldMetaKeys]?: string },
    data: {
        [x: string]: string;
    },
): boolean => {
    if (meta?.stateRegulation) {
        return meta.stateRegulation === data?.stateCode;
    }
    return false;
};
export const fullNameValidation = async ({
    firstName,
    lastName,
}: {
    firstName: string;
    lastName: string;
}) => {
    const { data, error } = await serverSideFieldValidation({
        ruleName: "fullName",
        value: {
            firstName,
            lastName,
        },
    });

    if (
        error &&
        error?.response &&
        (error?.response?.status === 502 ||
            error?.response?.status === 503 ||
            error?.response?.status === 504)
    ) {
        return {
            valid: true,
            message: "",
            newValue: undefined,
            validationSkipped: true,
        };
    }

    if (error || !data) {
        return {
            valid: false,
            message: getServerSideValidationErrorMessage(
                error as AxiosError,
                "Full Name",
            ),
            newValue: undefined,
        };
    }

    return {
        valid: true,
        message: "",
        newValue:
            data.replaceValue && typeof data.value === "string"
                ? data.value
                : undefined,
    };
};

export const fieldValidation = async (
    field: Field,
    value: string,
    locale: string | null,
    selectedCountryCode: string,
): Promise<{
    valid: boolean;
    message: string;
    newValue: string | { [x: string]: string } | undefined;
    hasRegulations: boolean;
    validationSkipped: boolean;
}> => {
    const conditions = [];
    const messages = require(`@i18n/shared/errorMessages/${
        locale as string
    }.json`) as JSONValue;

    const fieldName = field.niceName
        ? field.niceName
        : field.title
        ? field.title
        : "field";

    if (
        field.validationType &&
        field.validationType !== "companyName" &&
        field.validationType !== "streetAddress" &&
        value
    ) {
        const { data, error } = await serverSideFieldValidation({
            ruleName: field.validationType,
            value,
            countryCode: selectedCountryCode,
        });

        // if validation server is down (status === 503 || status === 504)
        // we need to skip the validation for the step and continue to the next step without any interruptions
        // we also save the fields that haven't been validated and send them in meta object on submit
        if (
            error &&
            error?.response &&
            (error?.response?.status === 502 ||
                error?.response?.status === 503 ||
                error?.response?.status === 504)
        ) {
            return {
                valid: true,
                message: "",
                newValue: value,
                hasRegulations: false,
                validationSkipped: true,
            };
        }

        if (error) {
            return {
                valid: false,
                message: getServerSideValidationErrorMessage(
                    error as AxiosError,
                    fieldName,
                ),
                newValue: undefined,
                hasRegulations: false,
                validationSkipped: false,
            };
        }

        if (data) {
            const hasRegulations = field.meta
                ? resolveRegulation(
                      field.meta,
                      data.value as { [x: string]: string },
                  )
                : false;

            return {
                valid: true,
                message: "",
                newValue:
                    data.replaceValue && typeof data.value === "string"
                        ? data.value
                        : undefined,
                hasRegulations,
                validationSkipped: false,
            };
        }
    }

    if (!value) {
        if (
            field.isRequired ||
            (field.conditionalLogic?.isActive &&
                field.conditionalLogic.requiredWhenShown)
        ) {
            conditions.push({
                valid: false,
                message: getErrorMessage({
                    messages,
                    fieldName,
                    errorType: "REQUIRED_ERROR",
                    field,
                }),
            });
        }
    } else {
        if (field.pattern) {
            const reg = new RegExp(field.pattern);

            const isValid = reg.test(String(value).toLowerCase());

            !isValid &&
                conditions.push({
                    valid: false,
                    message: getErrorMessage({
                        messages,
                        fieldName,
                        errorType: "INVALID_ERROR",
                        field,
                    }),
                });
        }

        if (
            (field.maxValue?.value || field.maxValue?.value === "0") &&
            field.fieldType !== "select" &&
            field.fieldType !== "radio" &&
            field.fieldType !== "range" &&
            field.fieldType !== "checkbox" &&
            field.fieldType !== "calculatedYears"
        ) {
            if (field.fieldType === "date" && field.maxValue?.type === "date") {
                const isValid =
                    dateDiff(
                        "d",
                        new Date(value),
                        new Date(field.maxValue?.value),
                    ) >= 0;

                !isValid &&
                    conditions.push({
                        valid: false,
                        message: getErrorMessage({
                            messages,
                            fieldName,
                            errorType: "MAX_DATE_ERROR",
                            field,
                        }),
                    });
            } else if (field.fieldType === "date") {
                const isValid =
                    dateDiff(
                        "d",
                        new Date(value),
                        new Date(gettingDateFromDays(field.maxValue?.value)),
                    ) >= 0;
                !isValid &&
                    conditions.push({
                        valid: false,
                        message: getErrorMessage({
                            messages,
                            fieldName,
                            errorType: "MAX_DATE_ERROR",
                            field,
                        }),
                    });
            } else {
                const isValid = value.trim().length <= field.maxValue.value;
                !isValid &&
                    conditions.push({
                        valid: false,
                        message: getErrorMessage({
                            messages,
                            fieldName,
                            errorType: "INVALID_ERROR",
                            field,
                        }),
                    });
            }
        }

        if (
            (field.minValue?.value || field.minValue?.value === "0") &&
            field.fieldType !== "select" &&
            field.fieldType !== "radio" &&
            field.fieldType !== "range" &&
            field.fieldType !== "checkbox" &&
            field.fieldType !== "calculatedYears"
        ) {
            if (field.fieldType === "date" && field.minValue?.type === "date") {
                const isValid =
                    dateDiff(
                        "d",
                        new Date(value),
                        new Date(field.minValue?.value),
                    ) <= 0;

                !isValid &&
                    conditions.push({
                        valid: false,
                        message: getErrorMessage({
                            messages,
                            fieldName,
                            errorType: "MIN_DATE_ERROR",
                            field,
                        }),
                    });
            } else if (field.fieldType === "date") {
                const isValid =
                    dateDiff(
                        "d",
                        new Date(gettingDateFromDays(field.minValue?.value)),
                        new Date(value),
                    ) >= 0;

                !isValid &&
                    conditions.push({
                        valid: false,
                        message: getErrorMessage({
                            messages,
                            fieldName,
                            errorType: "MIN_DATE_ERROR",
                            field,
                        }),
                    });
            } else {
                const isValid = value.trim().length >= field.minValue.value;
                !isValid &&
                    conditions.push({
                        valid: false,
                        message: getErrorMessage({
                            messages,
                            fieldName,
                            errorType: "INVALID_ERROR",
                            field,
                        }),
                    });
            }
        }
    }

    let valid = true;
    let message = "";
    if (conditions.length >= 1) {
        valid = false;
        message = conditions[0].message;
    }

    return { valid, message, newValue: value, hasRegulations: false };
};

export const handleFieldMasking = (
    mask: "us-phone" | "zipCode" | "postalCode",
    value: string,
) => {
    const handleUsPhoneNumberMasking = () => {
        const cleaned = value.replace(/[^0-9]/g, "");

        if (cleaned[0] === "1" && cleaned[1] === "1") {
            const x =
                cleaned.match(
                    /([1]{0,1})(1{0,1})([0-9]{0,3})([0-9]{0,3})([0-9]{0,4})/,
                ) ?? "";

            return (
                (x[1] ? "1" : "") +
                (x[3].length >= 1 ? (x[1] ? " (" : "(") : "") +
                (x[3] ? x[3] : "") +
                (x[4].length >= 1 ? ") " : "") +
                (x[4] ? x[4] : "") +
                (x[5].length >= 1 ? "-" : "") +
                (x[5] ? x[5] : "")
            );
        } else {
            const x =
                cleaned.match(
                    /([1]{0,1})([0-9]{0,3})([0-9]{0,3})([0-9]{0,4})/,
                ) ?? "";

            return (
                (x[1] ? "1" : "") +
                (x[2].length >= 1 ? (x[1] ? " (" : "(") : "") +
                (x[2] ? x[2] : "") +
                (x[3].length >= 1 ? ") " : "") +
                (x[3] ? x[3] : "") +
                (x[4].length >= 1 ? "-" : "") +
                (x[4] ? x[4] : "")
            );
        }
    };

    const handleZipCodeMasking = () => {
        const zipCodeAllowedCharacters = value.replace(/[^0-9\-]/g, "");
        const lastIndexOf = zipCodeAllowedCharacters.lastIndexOf("-");

        if (lastIndexOf != 5 && lastIndexOf != -1) {
            return zipCodeAllowedCharacters.substring(0, lastIndexOf);
        }

        const matchedPattern = zipCodeAllowedCharacters.match(
            /(^[0-9]+$)|(^[0-9]{5}-[0-9]{4,}$)/,
        );

        if (matchedPattern) {
            const dashIndexOf = matchedPattern[0].indexOf("-");
            const part2StartIndx = 5;
            const part1String = matchedPattern[0].substring(0, 5);
            const part2String = matchedPattern[0].substring(part2StartIndx, 10);

            return `${part1String}${
                part2String.length && dashIndexOf < 0 ? "-" : ""
            }${part2String}`;
        }
        return zipCodeAllowedCharacters.substring(0, 10);
    };
    const handlePostalCodeMasking = () => {
        const postalCodeAllowedCharacters = value
            .replace(/[^0-9a-zA-Z ]/g, "")
            .toUpperCase();
        const lastIndexOf = postalCodeAllowedCharacters.lastIndexOf(" ");

        if (lastIndexOf != 3 && lastIndexOf != -1) {
            return postalCodeAllowedCharacters.substring(0, lastIndexOf);
        }

        const matchedPattern = postalCodeAllowedCharacters.match(
            /(^[0-9a-zA-Z]+$)|(^[0-9a-zA-Z]{3} [0-9a-zA-Z]{3,}$)/,
        );

        if (matchedPattern) {
            const spaceIndexOf = matchedPattern[0].indexOf(" ");
            const part2StartIndx = 3;
            const part1String = matchedPattern[0].substring(0, 3);
            const part2String = matchedPattern[0].substring(part2StartIndx, 7);

            return `${part1String}${
                part2String.length && spaceIndexOf < 0 ? " " : ""
            }${part2String}`;
        }
        return postalCodeAllowedCharacters.substring(0, 7);
    };
    switch (mask) {
        case "us-phone":
            return handleUsPhoneNumberMasking();
        case "zipCode":
            return handleZipCodeMasking();
        case "postalCode":
            return handlePostalCodeMasking();
        default:
            return "";
    }
};
