import { FieldsData, Field, Colors } from "@lib/shared/types";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import Spinner from "@components/shared/spinner";
import { getLocationByZipCode } from "src/api";
import { FormField } from "@components/shared/form/formField";
import { handleFieldMasking } from "@lib/shared/form";
import { getZipCodeField } from "@lib/sharedUtils";

interface AddressDetailsProps {
    addressContent: string;
    colors: Colors;
    expandableFieldList: Field[];
    fieldsData: FieldsData;
    onChange: (field: Field, val: string) => void;
    setShowAddressDetails: React.Dispatch<React.SetStateAction<boolean>>;
    setAddressContent: React.Dispatch<React.SetStateAction<string>>;
    disableOnBlur: () => void;
}

export default function AddressDetails(
    props: AddressDetailsProps,
): ReactElement {
    const {
        addressContent,
        expandableFieldList,
        fieldsData,
        onChange,
        setShowAddressDetails,
        setAddressContent,
        disableOnBlur,
    } = props;

    const [expFieldsStates, updateExpFieldsStates] = useState<FieldsData>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const cancelBtnClicked = useRef<boolean>(false);
    const displayedFieldsList = expandableFieldList.filter(
        (f) => f.fieldType !== "hidden",
    );

    async function updateAddress(zipCode: string) {
        setIsLoading(true);
        const { data, error } = await getLocationByZipCode({ zipCode });
        setIsLoading(false);
        const zipCodeField: Field = getZipCodeField(expandableFieldList);
        // onExpandableFieldBlur(zipCodeField);
        if (error || !data || (!data.stateCode && !data.city)) {
            // setError("Invalid Zip Code");
            // onChange(zipCodeField, zipCode);
            updateExpFieldsStates({
                ...expFieldsStates,
                [zipCodeField.codeName]: {
                    ...expFieldsStates[zipCodeField.codeName],
                    value: zipCode,
                    errorMessage: "Invalid Zip Code",
                    valid: false,
                },
            });

            return;
        }

        updateExpFieldsStates({
            ...expFieldsStates,
            [zipCodeField.codeName]: {
                ...expFieldsStates[zipCodeField.codeName],
                value: zipCode,
                errorMessage: "",
                valid: true,
            },
        });
        onChange(zipCodeField, zipCode);
        setAddressContent(`${data.city}, ${data.stateCode} ${zipCode}`);
        localStorage.setItem("state", JSON.stringify(data.stateCode));
        localStorage.setItem("city", JSON.stringify(data.city));
        localStorage.setItem("zipCode", JSON.stringify(zipCode));
        setShowAddressDetails(false);
        disableOnBlur();
    }

    useEffect(() => {
        const newExpandableStates: FieldsData = {};

        expandableFieldList.forEach((field) => {
            newExpandableStates[field.codeName] = {
                ...fieldsData[field.codeName],
            };
        });
        updateExpFieldsStates(newExpandableStates);
    }, [fieldsData]);

    const changeExpandableFieldValue = (f: Field, val: string) => {
        let newVal = val;
        if (f.mask) {
            newVal = handleFieldMasking(f.mask, val);
        }
        onChange(f, val);
        updateExpFieldsStates({
            ...expFieldsStates,
            [f.codeName]: {
                ...expFieldsStates[f.codeName],
                value: newVal,
            },
        });
    };

    const handleClickEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            e.preventDefault();
            void updateAddress(
                expFieldsStates["zipCode"]?.value ??
                    expFieldsStates["toZipCode"]?.value,
            );
        }
    };

    const resetZipCode = () => {
        const zipCodeFromStorage = localStorage.getItem("zipCode");
        if (
            zipCodeFromStorage &&
            zipCodeFromStorage?.length &&
            expFieldsStates &&
            expFieldsStates?.["zipCode"]
        ) {
            // @ts-ignore
            onChange(expFieldsStates["zipCode"], zipCodeFromStorage);
        }
    };

    return (
        <div className={`${styles["addressDetailsInputs"]} rounded`}>
            <div>
                <div className={styles["topSection"]}>
                    <div
                        className={`font-bold text-base  ${styles["addressDetailsTitle"]}`}
                    >
                        {addressContent}
                    </div>
                </div>
                <div className={`${styles["btnSection"]}`}>
                    <div className="flex flex-col">
                        {isLoading ? (
                            <div className={styles["getZipCodeLoading"]}>
                                <Spinner
                                    size={50}
                                    color="green"
                                    success={false}
                                    className={styles["getZipCodeLoadSpinner"]}
                                />
                            </div>
                        ) : (
                            <>
                                {displayedFieldsList.map((f) => (
                                    <div
                                        className="flex flex-col  justify-center "
                                        key={f.codeName}
                                    >
                                        <label
                                            className={`${styles["fieldLabel"]} text-xs  py-1`}
                                        >
                                            {f.label ? `${f.label}` : <></>}
                                        </label>
                                        {expFieldsStates[f.codeName] && (
                                            <FormField
                                                onKeyDown={handleClickEnter}
                                                field={f}
                                                handleFieldBlur={() =>
                                                    setTimeout(async () => {
                                                        if (
                                                            !cancelBtnClicked.current
                                                        ) {
                                                            await updateAddress(
                                                                fieldsData[
                                                                    f.codeName
                                                                ].value,
                                                            );
                                                        }
                                                    }, 500)
                                                }
                                                isExpandable={true}
                                                handleFieldChange={(
                                                    f,
                                                    val: string,
                                                ) => {
                                                    changeExpandableFieldValue(
                                                        f,
                                                        val,
                                                    );
                                                }}
                                                fieldsData={{
                                                    [f.codeName]: {
                                                        ...expFieldsStates[
                                                            f.codeName
                                                        ],
                                                    },
                                                }}
                                                classes={{
                                                    fieldsClassName: {
                                                        all: styles[
                                                            "expandableField"
                                                        ],
                                                    },
                                                }}
                                            />
                                        )}
                                    </div>
                                ))}
                                <div
                                    className={`${styles["btnWrapper"]} pb-4 pl-0  pt-0`}
                                >
                                    <button
                                        type="button"
                                        onClickCapture={() => {
                                            cancelBtnClicked.current = true;
                                            resetZipCode();
                                            setShowAddressDetails(false);
                                        }}
                                        className={styles["btnCancelUpdate"]}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
