import React, { ReactElement } from "react";
import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";
import { getPageProps } from "@services/initial-calls";
import AcceptCreditWrapper from "@components/layouts/acceptcreditWrapper";
import ServiceForm from "@components/acceptcredit/serviceform";
import { useDomainContext } from "@hooks/useDomainContext";

const OffersList = dynamic(() => import("@components/shared/offersList"));
const WhyUs = dynamic(() => import("@components/acceptcredit/whyUs"));
const CardProcessor = dynamic(
    () => import("@components/acceptcredit/creditCardProcessor"),
);
const PickRightService = dynamic(
    () => import("@components/acceptcredit/pickRightService"),
);

export default function CarInjuryJusticeHome(): ReactElement {
    const { domain } = useDomainContext();

    return (
        <AcceptCreditWrapper>
            <ServiceForm />
            <OffersList offers={domain?.defaultOffers} />
            <CardProcessor />
            <WhyUs />

            <PickRightService />
        </AcceptCreditWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
