import { Buyer } from "@hooks/useForm/types";
import { getAccessToken, getLocale } from "@lib/shared/cookies";
import { Category, ServerRequest } from "@lib/shared/types";
import HTTP, { apiHandler } from "@services/http.service";
import { AxiosError, AxiosRequestConfig } from "axios";

interface PiiCompletionResponse {
    success: boolean;
    data: PiiCompletion | null;
}

export interface PiiCompletion {
    leadId: string;
    fields: { codeName: string; value: string }[];
    missingFields: string[];
    category: Category;
    buyers: Buyer[];
    operationId: string;
}

export const getPiiCompletion = async ({
    req,
    piiToken,
}: {
    req?: ServerRequest;
    piiToken: string;
}): Promise<{ data: PiiCompletion | null; error: AxiosError | null }> => {
    const accessToken = req ? req.accessToken : getAccessToken();

    const config: AxiosRequestConfig = {
        method: "get",
        url: `/api/web/v2/pii-completion-requests/${piiToken}`,
        headers: {
            "X-DOMAIN-NAME": `${req ? req?.headers?.host ?? "" : ""}`,
            Authorization: `Bearer ${accessToken as string}`,
            "X-Language": req ? req.locale ?? "en" : getLocale(),
        },
    };
    if (req) {
        const { data: formResponse, error } =
            await apiHandler<PiiCompletionResponse>(() => HTTP.server(config));

        return {
            data: formResponse?.data ?? null,
            error,
        };
    } else {
        const { data: formResponse, error } =
            await apiHandler<PiiCompletionResponse>(() => HTTP.client(config));

        return {
            data: formResponse?.data ?? null,
            error,
        };
    }
};
