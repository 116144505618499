import { useDomainContext } from "@hooks/useDomainContext";
import { useRouter } from "next/router";
import React, { ReactElement } from "react";
import styles from "./styles.module.scss";

export default function SmsTCPA(): ReactElement {
    const { domain, category } = useDomainContext();
    const router = useRouter();
    const showSmsTCPA = domain.config?.showSmsTCPA && !router.query.ts;
    const linkColor = domain.config?.smsTCPALinkColor
        ? domain.config?.smsTCPALinkColor
        : "rgb(63, 127, 234)";

    return showSmsTCPA ? (
        <>
            <div className={styles["checkbox-wrapper"]}>
                <input type="checkbox" id="sms_tcpa" name="sms_tcpa" />
                <label
                    htmlFor="sms_tcpa"
                    className={`flex justify-center ${styles["checkbox-label"]} cursor-pointer mb-1`}
                >
                    <span className={styles["box"]}>
                        <svg viewBox="0,0,50,50">
                            <path d="M5 30 L 20 45 L 45 5"></path>
                        </svg>
                    </span>
                    <span>
                        By submitting your number, you provide express written
                        consent to {domain.niceName} to contact you via SMS
                        about this{" "}
                        {category?.slug === "auto-accident"
                            ? "consultation"
                            : "quote"}{" "}
                        to the phone number provided above. Message frequency
                        varies. Text "STOP" to opt out. Message and data rates
                        may apply. For more information, please visit our{" "}
                        <a
                            target="_blank"
                            href="/privacy-policy"
                            style={{
                                color: linkColor,
                            }}
                        >
                            Privacy Policy
                        </a>{" "}
                        and{" "}
                        <a
                            target="_blank"
                            href="/terms-and-conditions"
                            style={{
                                color: linkColor,
                            }}
                        >
                            Terms of Use
                        </a>
                        . For assistance, contact us by texting "HELP" or "Help"
                        to{" "}
                        <a
                            target="_blank"
                            href="tel:+19094953188"
                            style={{
                                color: linkColor,
                            }}
                            rel="noreferrer"
                        >
                            909-495-3188
                        </a>
                    </span>
                </label>
            </div>
        </>
    ) : (
        <></>
    );
}
