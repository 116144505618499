import React, { ReactElement } from "react";

import colors from "@styles/variables/acceptcreditVariables.module.scss";
import FeatureAndFunctionalityIcon from "../icons/featureAndFunctionality";
import CustomerSupportIcon from "../icons/customerSupportIcon";
import EasyOfUseIcon from "../icons/easyOfUse";
import FeatureList from "@components/shared/featureList";

const whyUsList = [
    {
        title: "Easy to Use",
        description:
            "We have carefully selected companies that offer credit card processors that are easy to set up and intuitive to use. This includes effortless importing of data and invoices and the ability to view comprehensive reports with ease.",
        icon: <EasyOfUseIcon />,
    },
    {
        title: "Features & Benefits",
        description:
            "Our comparisons considered crucial features and tools, such as software integrations, data exports, invoicing capabilities, and user-friendly reporting dashboards.",
        icon: <FeatureAndFunctionalityIcon />,
    },
    {
        title: "Customer Support",
        description:
            "The companies we have partnered with provide a range of customer support options, including phone support, live chat, informative blogs, and comprehensive FAQs.",
        icon: <CustomerSupportIcon />,
    },
];

export default function WhyUs(): ReactElement {
    return (
        <FeatureList
            featureList={whyUsList}
            mainTitle=" Why Choose AcceptCredit.Cards? "
            colors={{
                bgColor: colors.whyUs,
                mainTitleColor: "white",
                titleColor: "white",
                descriptionColor: "white",
            }}
            classes={{
                mainSectionClasses: "max-w-7xl mx-auto block px-4vw lg:px-4",
                mainTitleClasses: "text-center text-xl  lg:text-3xl py-14",
                innerSectionClasses:
                    "lg:flex pb-4 lg:pb-0 flex-wrap justify-center",
                oneBoxClasses: "text-center mb-12 lg:mb-20 w-full lg:w-1/3",
                iconClasses: "children:mx-auto",
                titleClasses: "font-medium text-lg lg:text-xl mt-6",
                descriptionClasses:
                    "font-light text-sm text-center w-4/5 mx-auto mt-2",
            }}
        />
    );
}
