import { Buyer, ITextBlock } from "@hooks/useForm/types";
import {
    getAccessToken,
    getLocale,
    getTestVariantId,
    setTestVariantId,
} from "@lib/shared/cookies";
import { Step, ServerRequest } from "@lib/shared/types";
import HTTP, { apiHandler } from "@services/http.service";
import { AxiosRequestConfig } from "axios";
import { ServerResponse } from "http";

interface DomainFormResponse {
    success: boolean;
    data: DomainForm | null;
}
interface TestConfig {
    config?: {
        css?: string;
        formId?: number;
        formInsideModal?: boolean;
        mergeFirstInput?: boolean;
    };
    variantId?: number;
}
export interface supportedCountry {
    id: number;
    label: string;
    value: string;
}

export interface DomainForm {
    title: string;
    description: string;
    steps: Step[];
    id: number;
    stepToSendingData: number | null;
    successMessage: string;
    errorMessage: string;
    categorySlug: string;
    domainFormId: number | null;
    listingId: number;
    listing: { id: number; slug: string; modelType: string };
    testConfig?: TestConfig;
    supportedCountries: supportedCountry[];
    tCPAMessages?: { TCPAMessage: string }[];
    categoryName?: string;
    textBlocks: ITextBlock[];
    stepToFetchBuyers: number | null;
    defaultBuyers: Buyer[];
}

export const getDomainForm = async ({
    req,
    res,
    categorySlug,
}: {
    req?: ServerRequest;
    res?: ServerResponse;
    categorySlug: string;
}): Promise<{ data: DomainForm | null; error: Error | null }> => {
    const accessToken = req ? req.accessToken : getAccessToken();
    const variantId = getTestVariantId("form", categorySlug, req, res);

    const parsedVariantId = parseInt(variantId as string, 10);

    const numberOfServices = categorySlug.split(",").length;
    const endPoint =
        numberOfServices > 1 ? "/api/web/v1/forms/multi" : "/api/web/v2/forms";

    const config: AxiosRequestConfig = {
        method: "get",
        url: req
            ? `${endPoint}/${categorySlug}${
                  !isNaN(parsedVariantId)
                      ? `?variantId=${String(parsedVariantId)}`
                      : ""
              }`
            : `/api/form/${categorySlug}`,
        headers: {
            "X-DOMAIN-NAME": `${req ? req?.headers?.host ?? "" : ""}`,
            Authorization: `Bearer ${accessToken as string}`,
            "X-Language": req ? req.locale ?? "en" : getLocale(),
        },
    };
    if (req) {
        const { data: formResponse, error } =
            await apiHandler<DomainFormResponse>(() => HTTP.server(config));

        if (formResponse) {
            setTestVariantId(
                "form",
                String(formResponse?.data?.testConfig?.variantId),
                formResponse?.data?.categorySlug ?? null,
                req,
                res,
            );
        }

        return {
            data: formResponse?.data ?? null,
            error,
        };
    } else {
        const { data: formResponse, error } =
            await apiHandler<DomainFormResponse>(() => HTTP.client(config));

        return {
            data: formResponse?.data ?? null,
            error,
        };
    }
};
