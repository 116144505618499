import useDebounce from "@hooks/useDebounce";
import { useDomainContext } from "@hooks/useDomainContext";
import { Field } from "@lib/shared/types";
import React, {
    ReactElement,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { VisitDetails } from "src/api";
import styles from "./styles.module.scss";

const streetAddressCodename = ["streetAddress", "streetAddress2"];

interface InputProps {
    field: Field;
    onChange: (val: string) => void;
    onBlur: (val?: string) => void;
    value: string | null;
    className: string;
    type: React.HTMLInputTypeAttribute | undefined;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}
const consentGrantor: Record<string, string> = {
    email: "consent-grantor-email",
    phoneNumber: "consent-grantor-phone",
};
export default function Input(props: InputProps): ReactElement {
    const [changed, setChanged] = useState(false);
    const debouncedValue = useDebounce<string>(
        props.value ?? "",
        (props.value ? props.value.length : 0 > 2) ? 10000 : 0,
    );

    const { visitDetails, selectedCountryCode } = useDomainContext();

    let autoComplete: google.maps.places.Autocomplete;
    const finalDebouncedValue = useMemo(() => debouncedValue, [debouncedValue]);

    const streetAddressRef = useRef<HTMLInputElement>(null);

    const { onChange, onBlur, field, className, type, onKeyDown } = props;

    const handleGooglePlacesScriptLoaded = () => {
        if (streetAddressRef.current !== null) {
            autoComplete = new window.google.maps.places.Autocomplete(
                streetAddressRef.current,
                {
                    types: ["address"],
                    componentRestrictions: {
                        country: selectedCountryCode === "CA" ? "ca" : "us",
                    },
                    fields: [
                        "address_component",
                        "adr_address",
                        "alt_id",
                        "formatted_address",
                        "geometry",
                        "name",
                    ],
                },
            );
            autoComplete.addListener("place_changed", () =>
                handlePlaceSelect(),
            );
        }
    };

    const loadGooglePlacesScript = (callback: () => void) => {
        if (document.getElementById("googleStreetAddress")) {
            callback();
            return;
        }
        const script = document.createElement("script");
        script.type = "text/javascript";

        script.id = "googleStreetAddress";
        script.src = `https://maps.googleapis.com/maps/api/js?key=${
            process.env.NEXT_PUBLIC_GOOGLE_API_KEY as string
        }&libraries=places,geometry&callback=handleGooglePlacesScriptLoaded`;
        document.getElementsByTagName("head")[0].appendChild(script);
    };

    const handlePlaceSelect = () => {
        const addressObject = autoComplete.getPlace();
        if (addressObject?.address_components) {
            onChange(
                //@ts-ignore
                addressObject.address_components[0]["short_name"] +
                    " " +
                    //@ts-ignore
                    addressObject.address_components[1]["long_name"],
            );
        }
    };

    const handleClickEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (
            e.key === "Enter" &&
            streetAddressCodename.includes(field.codeName)
        ) {
            e.preventDefault();
        }
    };

    useEffect(() => {
        if (streetAddressCodename.includes(field.codeName)) {
            loadGooglePlacesScript(handleGooglePlacesScriptLoaded);
            // @ts-ignore
            window["handleGooglePlacesScriptLoaded"] =
                handleGooglePlacesScriptLoaded;
        }
    }, []);

    useEffect(() => {
        if (
            finalDebouncedValue.length > 2 &&
            changed &&
            !streetAddressCodename.includes(field.codeName)
        ) {
            setChanged(false);
            onBlur();
        }
    }, [finalDebouncedValue]);

    const getPlaceHolder = (field: Field, visitDetails: VisitDetails) => {
        if (field.codeName === "zipCode") {
            if (visitDetails?.postalCode) {
                if (visitDetails?.countryCode === "CA") {
                    return selectedCountryCode === "CA"
                        ? `e.g. ${visitDetails?.postalCode} ***`
                        : `e.g. 10010`;
                } else {
                    return selectedCountryCode === "CA"
                        ? "e.g. T2R ***"
                        : `e.g. ${visitDetails?.postalCode}`;
                }
            } else {
                return selectedCountryCode === "CA"
                    ? "e.g. T2R ***"
                    : field.placeholder;
            }
        } else {
            return field.placeholder;
        }
    };

    const getMaxValue = () => {
        if (field?.maxValue?.value && field.maxValue.type === "number")
            return parseInt(field?.maxValue?.value);
        return 250;
    };

    return (
        <>
            <input
                value={props.value ?? ""}
                onChange={(e) => {
                    onChange(e.target.value);
                    setChanged(true);
                }}
                className={`${styles["field"]} ${className ?? ""}`}
                placeholder={getPlaceHolder(field, visitDetails) ?? undefined}
                autoComplete={
                    streetAddressCodename.includes(field.codeName)
                        ? "off"
                        : field.autocomplete ?? undefined
                }
                type={type}
                data-tf-element-role={
                    consentGrantor[field.codeName] ?? undefined
                }
                //@ts-ignore
                onBlur={
                    streetAddressCodename.includes(field.codeName)
                        ? undefined
                        : onBlur
                }
                name={field.codeName ?? undefined}
                maxLength={getMaxValue()}
                ref={
                    streetAddressCodename.includes(field.codeName)
                        ? streetAddressRef
                        : undefined
                }
                onKeyDown={
                    onKeyDown
                        ? (e) => {
                              onKeyDown(e);
                          }
                        : (e) => {
                              handleClickEnter(e);
                          }
                }
                id={field.codeName}
            />
        </>
    );
}
