import { useDomainContext } from "@hooks/useDomainContext";
import { Variable } from "@page-builder/ts/generated";
import { Colors, PopupProps, SecondServiceColors } from "@lib/shared/types";
import { ReactElement } from "react";
import { DomainForm } from "src/api";
import dynamic from "next/dynamic";

const Form = dynamic(() => import("./formOld"));
const OneToOneForm = dynamic(() => import("./formNew"));

export interface Classes {
    formClassName?: string;
    stepClassName?: string;
    stepTitleClassName?: string;
    stepDescriptionClassName?: string;
    formFieldClassName?: string;
    formButtonClassName?: string;
    formStepInnerClassName?: string;
    fieldsClassName?: {
        all?: string;
        radio?: string;
        textarea?: string;
        input?: string;
        date?: string;
        select?: string;
        multiSelect?: string;
        checkbox?: string;
        zipcodeExpandable?: string;
        streetAddress?: string;
        range?: string;
    };
    fieldsWrapperClassName?: string;
    fieldNoteClassName?: string;
    tcpaClassName?: string;
    labelAsTitleClassName?: string;
    roundedLabelShift?: string;
    noteIndent?: string;
    formFieldLabelClassName?: string;
    formProgressbarClassName?: string;
    sellingPointClassName?: string;
    formSubmitButtonClassName?: string;
    formFieldDescriptionClassName?: string;
    fieldErrorMessageClassName?: string;
    stepTagClassName?: string;
    firstStepClass?: string;
    innerThankYouClassName?: string;
}
export interface StepsConfig {
    stepOrder?: number;
    hideTitle?: boolean;
    hideDescription?: boolean;
    forceStepTitle?: boolean;
    title?: string;
    description?: string;
    hideProgress?: boolean;
}
export type AnswerSummaryConfigs = AnswerSummaryConfig[];
export interface AnswerSummaryConfig {
    // step index to show the summary
    stepIndex: number;
    // fields to show in the summary
    fields: {
        codeName: string;
        gridArea: string;
        placeSelf?: Variable;
        className?: string;
        appendText?: string;
    }[];
    // styling
    gridTemplateAreas: Variable;
    gridTemplateColumns: Variable;
    gridTemplateRows: Variable;
    gap?: Variable;
    // if true then show summary until end of form
    extend?: boolean;
}

export interface FormProps {
    classes?: Classes;
    secondServiceClasses?: Classes;
    colors: Colors;
    secondServiceColors?: SecondServiceColors;
    fieldBorderRadius?: number;
    scrollToTop?: boolean;
    showProgress?: boolean;
    backButton?: {
        class?: string;
        label?: string;
        firstStepAction?: () => void;
    };
    sellingPoint?: string;
    sellingPointsSteps?: "ALL_STEPS" | "FIRST_STEP";
    customMainTitle?: string;
    preventWindowClose?: boolean;
    progressShift?: number;
    goToThankYouPage?: boolean;
    stepsConfig?: StepsConfig[] | undefined;
    dynamicThankYouFields?: string[];
    // PopupOffer?: ReactElement;
    popupStyle?: PopupProps;
    modalSize?: number;
    currentCategorySlug?: string;
    hideOffers?: boolean;
    ignoreOptionIcons?: boolean;
    customThankYouStep?: ReactElement;
    form?: DomainForm;
    isSecondServiceForm?: boolean;
    isPiiCompletionForm?: boolean;
    updateSecondServices?: (slug: string) => void;

    tags?: { [x: string]: { slug: string; active: boolean } };
    activeSecondServices?: string[];
    styles?: { [x: string]: string };
    secondServiceLoading?: boolean;
    showStepNumberOnly?: boolean;
    formTemplateProp?: "v1" | "v2";
    resetLeadId?: boolean;
    searchableMultiSelect?: boolean;
    secondServiceSearchableMultiSelect?: boolean;
    showInModal?: boolean;
    onModalClickClose?: () => void;
    answerSummaryConfigs?: AnswerSummaryConfigs;
    ignoreCategoryInThankYouPagePath?: boolean;
    isFormInsideOffersModal?: boolean;
    goToListPage?: boolean;
}
export interface StreetAddress {
    streetNumber?: string;
    route?: string;
    zipCode?: string;
    country?: string;
    city?: string;
    state?: string;
}

export interface MutationValue {
    tagName: string;
    outerHTML: string;
    type: string;
    domain: string;
}
export default function ResolvedForm(props: FormProps) {
    const { loadOneToOneForm } = useDomainContext();

    return loadOneToOneForm ? <OneToOneForm {...props} /> : <Form {...props} />;
}
