import { ReactElement } from "react";
import styles from "./style.module.scss";
import Form from "@components/shared/form";
import { useDomainContext } from "@hooks/useDomainContext";
import colors from "@styles/variables/carinjuryjusticeVariables.module.scss";
import { finalShowForm } from "@lib/sharedUtils";
import { useRouter } from "next/router";

import TextBlockOffer from "@components/shared/textBlockOffer/TextBlockOffer";

export default function ServiceForm(): ReactElement {
    const router = useRouter();

    const { visitDetails, category, domain } = useDomainContext();
    const isFormDisplayed = finalShowForm(
        router.query.sl as string,
        domain.showForm,
        domain.defaultOffers,
    );
    return (
        <>
            {isFormDisplayed ? (
                <div
                    className={`${styles.mainBanner} ${
                        styles[category?.slug as string]
                    } `}
                >
                    <div className={`${styles.formWrapper} `}>
                        <div>
                            <Form
                                classes={{
                                    formClassName: `${styles["form"]}`,
                                    stepTitleClassName: styles["step-title"],
                                    stepClassName: styles["step-block"],
                                    stepDescriptionClassName:
                                        styles["step-description"],
                                    formButtonClassName: styles["form-button"],
                                    formFieldClassName: styles["form-field"],
                                    formFieldLabelClassName:
                                        styles["field-label"],
                                    formStepInnerClassName:
                                        styles["steps-inner"],
                                    fieldsClassName: {
                                        radio: styles["radio-field"],
                                        checkbox: styles["checkbox-field"],
                                    },
                                    fieldsWrapperClassName:
                                        styles["fields-wrapper"],
                                    tcpaClassName: styles["tcpa"],
                                    labelAsTitleClassName:
                                        styles["label-as-step-title"],
                                    formSubmitButtonClassName:
                                        styles["submit-button"],
                                    sellingPointClassName:
                                        styles["selling-point"],
                                    formProgressbarClassName:
                                        styles["custom-progress"],
                                }}
                                colors={{
                                    primaryColor: "black",
                                }}
                                showProgress={true}
                                backButton={{
                                    class: styles["back-button"],
                                    label: "Back",
                                }}
                                sellingPoint={
                                    visitDetails?.region
                                        ? `${visitDetails?.region} Best Credit Card Processor`
                                        : ""
                                }
                                sellingPointsSteps="FIRST_STEP"
                                preventWindowClose
                                popupStyle={{
                                    iconColor: colors.mainColor,
                                    actionBtnBgColor: colors.mainColor,
                                }}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <TextBlockOffer />
            )}
        </>
    );
}
