import React, { ReactElement } from "react";
import Image from "next/image";
import styles from "./style.module.scss";
export default function PickRightService(): ReactElement {
    const scrollToTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };
    return (
        <section className="mt-10 lg:my-16">
            <div className="max-w-7xl mx-auto block px-4vw lg:px-4">
                <div className="flex flex-col lg:flex-row justify-between">
                    <div className={styles["imgZone"]}>
                        <Image
                            src="/acceptcredit/assets/images/picOpt.jpg"
                            className={styles["backgroundImg"]}
                            alt="creditCardProcessor"
                            layout="responsive"
                            width={472}
                            height={628}
                        />
                    </div>
                    <div className={`${styles["brief"]} w-full lg:w-3/5`}>
                        <h2 className="text-xl  lg:text-[22px] mb-8 font-bold">
                            Choose the Perfect Credit Card Processing Services
                            Provider for You
                        </h2>
                        <div className="text-sm lg:text-lg mb-12 font-light">
                            <p className="mb-6 lg:mb-8">
                                Accepting payments is a fundamental aspect of
                                any business. In today's world, where most
                                transactions occur electronically, securely
                                accepting various forms of payment can be a
                                challenge. This is where credit card processing
                                service providers come in.
                            </p>
                            <p className="mb-6 lg:mb-8">
                                They offer an affordable and secure solution for
                                accepting payments while providing timely access
                                to your funds. With so many options available,
                                it can be overwhelming to choose the right
                                provider. Fill out our quick form to discover
                                more about credit card processing services and
                                find the perfect fit for your business.
                            </p>
                        </div>
                        <button
                            onClick={() => scrollToTop()}
                            style={{ backgroundColor: "#316767" }}
                            className={` text-white mt-20 py-4 sm:px-11 px-4 text-sm sm:text-lg font-semibold rounded-lg block mx-auto mb-20 hover:-translate-y-2 hover:transition-all transition-all`}
                        >
                            Get My Free Credit Card Processor Quote Now
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
}
