import { getAccessToken, setTimeOnSiteCookie } from "@lib/shared/cookies";
import { ClickOffer } from "@lib/shared/types";
import { PageProps } from "@services/initial-calls";
import { useRouter } from "next/router";
import {
    createContext,
    Dispatch,
    ReactElement,
    ReactNode,
    SetStateAction,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { DomainForm } from "src/api";
import { sendTrackingTime } from "src/api/trackingTime";
import { useTimeOnSite } from "./useTimeOnSite";

type DomainContextProps = Pick<
    PageProps,
    | "visitDetails"
    | "zipCodeLocation"
    | "domain"
    | "domainPopup"
    | "comparisonListing"
    | "formattedTitle"
    | "categoryListing"
    | "category"
    | "clicksOffers"
    | "zipCode"
    | "form"
    | "locale"
    | "defaultLocale"
    | "locales"
    | "content"
    | "userAgent"
    | "isBot"
    | "formTemplate"
    | "formOffer"
    | "exitModalOffer"
    | "thankYouOffers"
    | "headerOffer"
    | "thankyouListing"
    | "secondListing"
    | "stickyOffer"
    | "testConfig"
    | "blogPost"
    | "autoWarrantyMakers"
    | "totalTimeSpendOnSite"
    | "routeConfig"
    | "externalFavicon"
    | "pageBuilderBlogPost"
    | "pageBuilderLookupLists"
    | "pageBuilderListings"
    | "piiCompletionData"
    | "redirectTo"
    | "pageType"
    | "doNotSellFormStates"
    | "disableTimeOnSite"
    | "showFormModal"
    | "loadOneToOneForm"
> & {
    updateClicksOffers: Dispatch<
        SetStateAction<ClickOffer[] | null | undefined>
    >;
    updateForm: Dispatch<SetStateAction<DomainForm | null | undefined>>;
    selectedCountryCode: string | null;
    updateSelectedCountryCode: Dispatch<SetStateAction<string>>;
};

const DomainContext = createContext<DomainContextProps>({});

export const DomainProvider = (
    props: PageProps & { children: ReactNode },
): ReactElement => {
    const {
        visitDetails,
        domain,
        formattedTitle,
        domainPopup,
        categoryListing,
        comparisonListing,
        zipCodeLocation,
        category,
        clicksOffers: offers,
        zipCode,
        form: domainForm,
        locale,
        locales,
        defaultLocale,
        content,
        userAgent,
        isBot,
        formTemplate,
        formOffer,
        headerOffer,
        stickyOffer,
        exitModalOffer,
        thankYouOffers,
        thankyouListing,
        secondListing,
        testConfig,
        blogPost,
        initialTimeOnSite,
        routeConfig,
        externalFavicon,
        pageBuilderBlogPost,
        pageBuilderLookupLists,
        pageBuilderListings,
        autoWarrantyMakers,
        piiCompletionData,
        redirectTo,
        disableTimeOnSite,
        pageType,
        doNotSellFormStates,
    } = props;

    const [userSelectedCountryCode, updateSelectedCountryCode] = useState(
        visitDetails?.countryCode,
    );
    const { query } = useRouter();
    const selectedCountryCode = useMemo(() => {
        if (
            !domainForm?.supportedCountries ||
            !domainForm?.supportedCountries?.length
        ) {
            return null;
        }

        if (query?.countryCode) {
            return query?.countryCode as string;
        }

        return userSelectedCountryCode;
    }, [query?.countryCode, userSelectedCountryCode]);

    const [clicksOffers, updateClicksOffers] = useState(offers);
    const [form, updateForm] = useState(domainForm);
    const {
        totalTimeSpendOnSite,
        startTrackingTime,
        stopTrackingTime,
        trackTime,
        prevTotalTimeSpendOnSite,
    } = useTimeOnSite(initialTimeOnSite ?? 0, disableTimeOnSite);

    const onVisibilityChange = () => {
        if (document.visibilityState === "visible") startTrackingTime();
        else {
            stopTrackingTime();
        }
    };

    const beforeUnload = () => {
        stopTrackingTime();
        const accessToken = getAccessToken();
        if (
            accessToken &&
            prevTotalTimeSpendOnSite.current !== totalTimeSpendOnSite.current &&
            totalTimeSpendOnSite.current / 1000 > 10 &&
            process.env.NODE_ENV !== "development"
        ) {
            prevTotalTimeSpendOnSite.current = totalTimeSpendOnSite.current;
            setTimeOnSiteCookie(totalTimeSpendOnSite.current / 1000);
            void sendTrackingTime({
                event: "timeOnSite",
                eventValue: (totalTimeSpendOnSite.current / 1000).toString(),
                eventCategory: "meta",
            });
        }
    };

    useEffect(() => {
        window.setInterval(function () {
            if (document.visibilityState === "visible") {
                trackTime();
            }
        }, 1000);

        window.setInterval(() => {
            const token = getAccessToken();
            if (
                token &&
                prevTotalTimeSpendOnSite.current !==
                    totalTimeSpendOnSite.current &&
                process.env.NODE_ENV !== "development"
            ) {
                prevTotalTimeSpendOnSite.current = totalTimeSpendOnSite.current;
                void sendTrackingTime({
                    event: "timeOnSite",
                    eventValue: (
                        totalTimeSpendOnSite.current / 1000
                    ).toString(),
                    eventCategory: "meta",
                });
            }
        }, 10000);

        window.addEventListener("visibilitychange", onVisibilityChange);
        window.addEventListener("beforeunload", beforeUnload);

        return () => {
            window.removeEventListener("visibilitychange", onVisibilityChange);
            window.removeEventListener("beforeunload", beforeUnload);
        };
    }, []);

    const loadOneToOneForm =
        (query.oto === "true" || category?.isOneToOneConsent) &&
        query.oto !== "false";

    return (
        <DomainContext.Provider
            value={{
                visitDetails,
                domain,
                formattedTitle,
                domainPopup,
                comparisonListing,
                categoryListing,
                zipCodeLocation,
                category,
                clicksOffers,
                zipCode,
                updateClicksOffers,
                form,
                updateForm,
                locale,
                defaultLocale,
                locales,
                content,
                userAgent,
                isBot,
                formTemplate,
                formOffer,
                headerOffer,
                stickyOffer,
                exitModalOffer,
                thankYouOffers,
                thankyouListing,
                secondListing,
                testConfig,
                blogPost,
                selectedCountryCode,
                updateSelectedCountryCode,
                totalTimeSpendOnSite: totalTimeSpendOnSite,
                routeConfig,
                externalFavicon,
                pageBuilderBlogPost,
                autoWarrantyMakers,
                pageBuilderLookupLists,
                pageBuilderListings,
                piiCompletionData,
                redirectTo,
                pageType,
                doNotSellFormStates,
                loadOneToOneForm,
            }}
        >
            {props.children}
        </DomainContext.Provider>
    );
};

export const useDomainContext = () => useContext(DomainContext);
